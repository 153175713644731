/* width */
::-webkit-scrollbar {
    width: 8px;
    background: #000000;
}
  
  
/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #999; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #DDD; 
}